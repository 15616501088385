/** @format */

import React from "react";
import style from "../assets/style/Contact.module.css";

function ContactPage() {
  const handleViewHotelList = () => {
    // Replace the URL with the actual URL of the PDF file
    window.open(
      "https://storage.googleapis.com/bulletin-ewad/Convention%20Partner%20Hotels%20v2.0.pdf",
      "_blank"
    );
  };

  return (
    <div className={`${style.contact_container} py-5`}>
      <div className="px-sm-5 px-2 w-100">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="mb-5" style={{ fontWeight: "900" }}>
              Contact Us
            </h2>
            <p className="mb-5" style={{ fontWeight: "400", fontSize: "24px" }}>
              Please feel free to reach out to us via email for any inquiries or
              assistance.
            </p>
          </div>
          <div className="col-12">
            <div className="row m-0">
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.emailContainer}`}>
                  <h3>General Inquiries</h3>
                  <p>
                    <a href="mailto:ewadconvention@amorc.org.ng">
                      ewadconvention@amorc.org.ng
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.emailContainer}`}>
                  <h3>Technical Support</h3>
                  <p>
                    <a href="mailto:itdepartment@amorc.org.ng">
                      itdepartment@amorc.org.ng
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.emailContainer}`}>
                  <h3>
                    Entry Visa, Vaccination and Hotel Accommodation Information
                    for international members
                  </h3>
                  <p>
                    <a href="mailto:e.ikpeme@amorc.org.ng">
                      e.ikpeme@amorc.org.ng
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <div className={`${style.emailContainer}`}>
                  <h3>
                    Hotel Accommodation Information for Nigeria and Ghana
                    members
                  </h3>
                  <p>
                    <a href="mailto:eglwaconvention@amorc.org.ng">
                      eglwaconvention@amorc.org.ng
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <button
              className="btn app-btn-primary"
              onClick={handleViewHotelList}>
              View Hotel List
            </button>
          </div>

          {/* <div className="col-6 text-center mt-5">
              <button className="btn app-btn-primary" onClick={handleViewHotelList}>
                View Visa Information
              </button>
            </div>*/}
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
