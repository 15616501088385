/** @format */

import React from "react";
import Hero from "./Hero";
import About from "./About";
import Event from "./Event";
import Speaker from "./Speaker";
import Feature from "./Feature";
import Enlignment from "./Enlignment";
import Community from "./Community";
import Dignitaries from "./Dignitaries";
import Gallery from "./Gallery";
import ContactPage from "./Contact";

export default function Layout() {
  return (
    <>
      <Hero />
      <About />
      <Event />
      <Speaker />
      <Feature />
      <Enlignment />
      <Community />
      <Dignitaries />
      <Gallery />
      <ContactPage />
    </>
  );
}
